/**
 * The `Configuration` class provides methods to manage and retrieve configuration settings.
 */
export class Configuration {
    #configs;

    /**
     * Creates an instance of the `Configuration` class.
     * @constructor
     * @param {Object} configs - Configuration settings object.
     */
    constructor(configs) {
        this.#configs = configs;
    }

    /**
     * Fetches the configuration object for a given configuration name.
     * @param {string} configName - The name of the configuration setting to retrieve.
     * @returns {Object} The configuration object matching the provided name.
     */
    getConfig(configName) {
        return this.#configs.find((element) => element.name === configName)
    }

    /**
     * Determines if the objectives score should be shown.
     * @returns {boolean} True if the display objectives score should be shown, otherwise false.
     */
    isObjectivesScoreShow() {
        const configName = "Display objectives score";
        const value = this.getConfig(configName);
        return value?.value;
    }

    /**
     * Determines if the objectives score should be shown in self profile.
     * @returns {boolean} True if the display self objectives score should be shown, otherwise false.
     */
    isObjectivesSelfScoreShow() {
        const configName = "Display self objectives score";
        const value = this.getConfig(configName);
        return value?.value;
    }

    /**
     * Determines if the empty boxes should be shown in the succession chart.
     * @returns {boolean} True if the display succession empty boxes should be shown, otherwise false.
     */
    isShowEmptyBoxes() {
        const configName = "Display Succession Empty Boxes";
        const value = this.getConfig(configName);
        return value?.value;
    }

}